<template>
  <div>
    <div class="content-block">
      <h2>{{ texts[currentLanguage].finishedGoods }}</h2>
    </div>

    <DxDataGrid
        class="dx-card wide-card"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="false"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @exporting="onExporting">
      >

      <DxExport :enabled="true"/>
      <DxFilterRow :visible="true"/>
      <DxScrolling column-rendering-mode="virtual"/>
      <DxHeaderFilter :visible="true"/>

      <DxToolbar>
        <DxItem location="before">
          <DxSelectBox
              ref="selectBoxOptionsTourComponent"
              label="Jahr"
              width="180"
              labelMode="floating"
              placeholder="Wähle ein Jahr"
              :value="supplierDataStore.availableYears[0]"
              :data-source="supplierDataStore.availableYears"
              @value-changed="selectBoxTourValueChangedEvent"
          />
        </DxItem>

        <DxItem location="before">
          <DxSelectBox
              label="Sprache"
              width="180"
              labelMode="floating"
              placeholder="Wähle eine Sprache"
              valueExpr="name"
              display-Expr="description"
              :value="currentLanguage"
              :data-source="languages"
              @value-changed="languageCheckBoxChanged"
          />
        </DxItem>
      </DxToolbar>

      <DxEditing
          mode="batch"
          :allow-adding="false"
          :allow-deleting="false"
          :allow-updating="false"
      />

      <dx-paging :page-size="10"/>
      <dx-pager :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="false"/>

      <dx-column
          data-field="supplier.name"
          :caption="texts[currentLanguage].grid['supplier']"
          data-type="string"
          :adaptive-priority="3"
          :visible="true"
          :allow-editing="false"
      />

      <dx-column
          data-field="net_weight_cotton_conventional"
          :caption="texts[currentLanguage].grid['net_weight_cotton_conventional']"
          data-type="string"
          :allow-editing="false"
          :visible="true"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_cotton_fairtrade_ocs"
          :caption="texts[currentLanguage].grid['net_weight_cotton_fairtrade_ocs']"
          :allow-editing="true"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_cotton_fairtrade_gots"
          :caption="texts[currentLanguage].grid['net_weight_cotton_fairtrade_gots']"
          data-type="string"
          :allow-editing="false"
          :visible="true"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_cotton_gots"
          :caption="texts[currentLanguage].grid['net_weight_cotton_gots']"
          data-type="string"
          :allow-editing="false"
          :visible="true"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_cotton_cmia"
          :caption="texts[currentLanguage].grid['net_weight_cotton_cmia']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_polyester_conventional"
          :caption="texts[currentLanguage].grid['net_weight_polyester_conventional']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_polyester_rcs"
          :caption="texts[currentLanguage].grid['net_weight_polyester_rcs']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_polyester_grs"
          :caption="texts[currentLanguage].grid['net_weight_polyester_grs']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />

      <dx-column
          data-field="net_weight_tencel"
          :caption="texts[currentLanguage].grid['net_weight_tencel']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />

      <dx-column v-if="currentSelectedYear >= 2025"
          data-field="net_weight_linen"
          :caption="texts[currentLanguage].grid['net_weight_linen']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />

      <dx-column
          v-if="currentSelectedYear <= 2023"
          data-field="net_weight_refibra"
          :caption="texts[currentLanguage].grid['net_weight_refibra']"
          data-type="string"
          :allow-editing="false"
          :format="numberWithCommas"
      />


      <DxSummary :calculate-custom-summary="sumAmount">
        <DxTotalItem name="totalSum" :display-format="roundAmount" summary-type="custom"
                     show-in-column="supplier.name"/>
        <DxTotalItem name="net_weight_cotton_conventional" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_cotton_conventional"/>

        <DxTotalItem name="net_weight_cotton_fairtrade_ocs" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_cotton_fairtrade_ocs"/>
        <DxTotalItem name="net_weight_cotton_fairtrade_gots" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_cotton_fairtrade_gots"/>
        <DxTotalItem name="net_weight_cotton_gots" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_cotton_gots"/>
        <DxTotalItem name="net_weight_cotton_cmia" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_cotton_cmia"/>
        <DxTotalItem name="net_weight_polyester_conventional" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_polyester_conventional"/>
        <DxTotalItem name="net_weight_polyester_rcs" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_polyester_rcs"/>
        <DxTotalItem name="net_weight_polyester_grs" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_polyester_grs"/>
        <DxTotalItem name="net_weight_tencel" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_tencel"/>
        <DxTotalItem name="net_weight_linen" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_linen"/>
        <DxTotalItem name="net_weight_refibra" :display-format="roundAmount" summary-type="custom"
                     show-in-column="net_weight_refibra"/>
      </DxSummary>
    </DxDataGrid>


    <div v-if="supplierDataStore.availableYears.length <= 0">

      <div class="d-flex w-100 h-100 mt-5 justify-center align-center">
        Keine Daten
      </div>

    </div>

    <div class="pa-6" v-if="supplierDataStore.availableYears.length > 0">
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row w-100">
          <div class="w-100">
            <DxChart
                id="chart"
                :data-source="calculateTotalAmountInKG(true)"
                palette="Soft"
            >
              <DxExport :enabled="true"/>

              <DxCommonSeriesSettings
                  :ignore-empty-points="true"
                  argument-field="name"
                  value-field="value"
                  type="bar"
              />
              <DxSeriesTemplate name-field="name" argument-field="value"/>
              <DxTitle
                  :text="this.texts[this.currentLanguage].chartAndBoxes['uberschriftGesamtmengeInKg'] + ' - ' + currentSelectedYear "/>

              <DxArgumentAxis>
                <DxLabel overlappingBehavior="none"/>
              </DxArgumentAxis>

            </DxChart>
          </div>
        </div>
        <div class="d-flex flex-row w-100">
          <div class="w-50">
            <DxPieChart
                id="pie"
                :data-source="calculateTotalAmountPercentage(true)"
                resolveLabelOverlapping="shift"
                palette="Bright"

                :title="this.texts[this.currentLanguage].chartAndBoxes['ueberschriftGesamtmengeProzentualBaumwolle']"
                type="doughnut"
            >
              <DxExport :enabled="true"/>

              <DxSeries argument-field="name" value-field="value">
                <DxLabel :visible="true" :format="{ type: 'percent', precision: 2 }" position="inside">
                  <DxConnector :visible="true" :width="1"/>
                </DxLabel>
              </DxSeries>
              <DxSize/>
            </DxPieChart>
          </div>
          <div class="w-50">
            <DxPieChart
                id="pie"
                :data-source="calculateTotalAmountPercentagePolyester(true)"
                palette="Bright"
                resolveLabelOverlapping="shift"
                :title="this.texts[this.currentLanguage].chartAndBoxes['ueberschriftGesamtmengeProzentualPolyester']"
                type="doughnut"
            >
              <DxExport :enabled="true"/>
              <DxSeries argument-field="name" value-field="value">
                <DxLabel :visible="true" :format="{ type: 'percent', precision: 2 }" position="inside">
                  <DxConnector :visible="true" :width="1"/>
                </DxLabel>
              </DxSeries>
              <DxSize/>
            </DxPieChart>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column w-100">
        <h5 class="mt-2 mb-2"><b>{{ this.texts[this.currentLanguage].chartAndBoxes['uberschriftGesamtmengeInKg'] }}</b>
        </h5>

        <div class="w-100">
          <v-container :fluid="true">
            <v-row>
              <v-col v-for="(value, index) in calculateTotalAmountInKG(false)" :key="index" cols="3">
                <v-card class="pa-4 summary-card d-flex flex-column h-100">
                  <v-card-title class="break-word">
                    {{ value.name }}

                  </v-card-title>
                  <v-card-text class="flex-grow-1">
                    {{ formatWeight(value.value) }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <h5 class="mt-2 mb-2">
          <b>{{ this.texts[this.currentLanguage].chartAndBoxes['ueberschriftGesamtmengenProzentual'] }}</b></h5>
        <div class="w-100">
          <v-container :fluid="true">
            <v-row align="stretch">
              <v-col v-for="(value, index) in calculateTotalAmountPercentage(false)" :key="index" cols="4">
                <v-card class="pa-4 summary-card d-flex flex-column h-100">
                  <v-card-title class="break-word">
                    {{ value.name }}
                  </v-card-title>
                  <v-card-text class="flex-grow-1">
                    {{ (value.value * 100).toFixed(2) + '%' }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>

        <h5 class="mt-2 mb-2">
          <b>{{ this.texts[this.currentLanguage].chartAndBoxes['ueberschriftGesamtmengeProzentualPolyester'] }}</b></h5>
        <div class="w-100">
          <v-container :fluid="true">
            <v-row align="stretch">
              <v-col v-for="(value, index) in calculateTotalAmountPercentagePolyester(false)" :key="index" cols="4">
                <v-card class="pa-4 summary-card d-flex flex-column h-100">
                  <v-card-title class="break-word">
                    {{ value.name }}
                  </v-card-title>
                  <v-card-text class="flex-grow-1">
                    {{ (value.value * 100).toFixed(2) + '%' }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
  <consumption-per-year-table :current-year="currentSelectedYear" :language="currentLanguage"/>

</template>

<script>
import DataSource from "devextreme/data/data_source";
import {DxSelectBox} from "devextreme-vue/select-box";
import 'devextreme/localization/messages/de.json';

import DxDataGrid, {
  DxColumn,
  DxPager,
  DxTotalItem,
  DxSummary,
  DxPaging,
  DxExport,
  DxItem,
  DxFilterRow,
  DxScrolling,
  DxHeaderFilter,
  DxToolbar,
  DxEditing,
} from "devextreme-vue/data-grid";

import DxChart, {
  DxSeriesTemplate,
  DxTitle,
  DxCommonSeriesSettings,
  DxLabel,
  DxSeries, DxArgumentAxis,
} from "devextreme-vue/chart";

import DxPieChart, {DxSize, DxConnector} from "devextreme-vue/pie-chart";
import {useFinishedGoodsConsumptionStore} from "@/stores/finishedGoodsConsumptionStore";
import {useSupplierDataStore} from "@/stores/SupplierDataStore";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import ConsumptionPerYearTable from "@/components/finishedGoodsConsumption/consumptionPerYearTable.vue";
import {saveAs} from "file-saver";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const selectBoxStatusComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    ConsumptionPerYearTable,
    DxArgumentAxis,
    DxDataGrid,
    DxTitle,
    DxScrolling,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxExport,
    DxItem,
    DxSummary,
    DxPager,
    DxTotalItem,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
    DxChart,
    DxLabel,
    DxSeries,
    DxPieChart,
    DxSize,
    DxConnector,
    DxSeriesTemplate,
    DxCommonSeriesSettings,
  },

  setup() {

    const finishedGoodsConsumptionStore = useFinishedGoodsConsumptionStore();
    const supplierDataStore = useSupplierDataStore();

    return {finishedGoodsConsumptionStore, supplierDataStore}

  },

  async mounted() {
    await this.supplierDataStore.getAvailableYears();
  },

  data() {

    let currentSelectedYear = this.supplierDataStore.availableYears[0]

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "id",
      load: async () => {
        await this.supplierDataStore.getAvailableYears();
        await this.finishedGoodsConsumptionStore.getFinishedGoodsConsumptionsByYear(this.tourSelectBoxInstance.option("value"));
        return this.finishedGoodsConsumptionStore.finishedGoodsConsumptionData;
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};
    const totalConsumptionData = {};
    const totalConsumptionDataPercentage = {};
    const pieChartTotalConsumptionPercentage = {};
    const pieChartPolyesterTotalConsumptionPercentage = {};
    const barChartTotalConsumption = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {

      languages: [{name: 'en', description: "English"}, {name: 'de', description: "Deutsch"}],

      texts: {
        en: {
          finishedGoods: "Finished Goods Consumption",
          grid: {
            supplier: "Supplier",
            net_weight_cotton_conventional: "Cotton Conventional (kg)",
            net_weight_cotton_fairtrade_ocs: "Cotton Fairtrade / OCS (kg)",
            net_weight_cotton_fairtrade_gots: "Cotton Fairtrade / GOTS (kg)",
            net_weight_cotton_gots: "Cotton GOTS (kg)",
            net_weight_cotton_cmia: "Cotton Made in Africa (kg)",
            net_weight_polyester_conventional: "Polyester Conventional (kg)",
            net_weight_polyester_rcs: "Polyester RCS (kg)",
            net_weight_polyester_grs: "Polyester GRS (kg)",
            net_weight_tencel: "Tencel (kg)",
            net_weight_linen: "Linen (kg)",
            net_weight_refibra: "Refibra (kg)",
          },
          chartAndBoxes: {
            anteilRecycledPolyesterAnGesamtmengePolyester: "Share of Recycled polyester in total polyester",
            anteilRCSAnGesamtmengePolyester: "Share of RCS in total polyester",
            anteilGRSAnGesamtmengePolyester: "Share of GRS in total polyester",

            restlicheBaumwolle: "Remaining cotton",
            restlichePolyester: "Remaining polyester",

            ueberschriftGesamtmengeProzentualBaumwolle: "Total quantity cotton in %",
            ueberschriftGesamtmengeProzentualPolyester: "Total quantity polyester in %",
            ueberschriftGesamtmengenProzentual: "Total quantity in %",
            uberschriftGesamtmengeInKg: "Total quantity in kg / to.",

            anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle: "Share of sustainable cotton in total cotton",
            anteilFairtradeAnGesamtmengeBaumwolle: "Fairtrade share of total cotton",
            anteilGOTSAnGesamtmengeBaumwolle: "Share of GOTS in total cotton",
            anteilOCSAnGesamtmengeBaumwolle: "Share of OCS in total cotton",
            anteilCmiaAnGesamtmengeBaumwolle: "Share of CmiA in total cotton",

            baumwolleGesamt: "Total Cotton (conventional and sustainable)",
            baumwolleKonventionell: "Total Cotton conventional",
            baumwolleNachhaltig1: "Total Cotton sustainable (Fairtrade, OCS, GOTS, CmiA)",
            baumwolleNachhaltig2: "Cotton sustainable (Fairtrade, OCS, GOTS)",
            baumwolleCmia: "Cotton sustainable (CmiA)",
            polyesterGesamt: "Total Polyester (conventional and sustainable)",
            polyesterKonventionell: "Polyester conventional",
            polyesterRecycled: "Polyester recycled (GRS, RCS)",
            tencel: "Tencel",
            linen: "Linen",
            refibra: "Refibra",
          },
        },
        de: {
          finishedGoods: "Materialeinsatz für Endprodukt.",
          grid: {
            supplier: "Lieferant",
            net_weight_cotton_conventional: "Konventionelle Baumwolle (kg)",
            net_weight_cotton_fairtrade_ocs: "Baumwolle Fairtrade / OCS (kg)",
            net_weight_cotton_fairtrade_gots: "Baumwolle Fairtrade / GOTS (kg)",
            net_weight_cotton_gots: "Baumwolle GOTS (kg)",
            net_weight_cotton_cmia: "Baumwolle Made in Africa (kg)",
            net_weight_polyester_conventional: "Konventionelles Polyester (kg)",
            net_weight_polyester_rcs: "Polyester RCS (kg)",
            net_weight_polyester_grs: "Polyester GRS (kg)",
            net_weight_tencel: "Tencel (kg)",
            net_weight_linen: "Leinen (kg)",
            net_weight_refibra: "Refibra (kg)"
          },

          chartAndBoxes: {

            restlicheBaumwolle: "Anteil restliche Baumwolle",

            ueberschriftGesamtmengeProzentualBaumwolle: "GESAMTMENGEN PROZENTUAL BAUMWOLLE",
            ueberschriftGesamtmengeProzentualPolyester: "GESAMTMENGEN PROZENTUAL POLYESTER",
            ueberschriftGesamtmengenProzentual: "GESAMTMENGEN PROZENTUAL",
            uberschriftGesamtmengeInKg: "GESAMTMENGEN IN KG / TO.",

            restlichePolyester: "Restliches Polyester",

            anteilRecycledPolyesterAnGesamtmengePolyester: "Anteil des recycelten Polyesters an der Gesamtmenge",
            anteilRCSAnGesamtmengePolyester: "Anteil von RCS an der Gesamtmenge Polyester",
            anteilGRSAnGesamtmengePolyester: "Anteil von GRS an der Gesamtmenge Polyester",

            anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle: "Anteil der nachhaltigen Baumwolle an der Gesamtmenge Baumwolle",
            anteilFairtradeAnGesamtmengeBaumwolle: "Fairtrade-Anteil an der Gesamtmenge Baumwolle",
            anteilGOTSAnGesamtmengeBaumwolle: "Anteil von GOTS an der Gesamtmenge Baumwolle",
            anteilOCSAnGesamtmengeBaumwolle: "Anteil von OCS an der Gesamtmenge Baumwolle",
            anteilCmiaAnGesamtmengeBaumwolle: "Anteil von CmiA an der Gesamtmenge Baumwolle",

            baumwolleGesamt: "Gesamtbaumwolle (konventionell und nachhaltig)",
            baumwolleKonventionell: "Gesamte konventionelle Baumwolle",
            baumwolleNachhaltig1: "Gesamte nachhaltige Baumwolle (Fairtrade, OCS, GOTS, CmiA)",
            baumwolleNachhaltig2: "Nachhaltige Baumwolle (Fairtrade, OCS, GOTS)",
            baumwolleCmia: "Nachhaltige Baumwolle (CmiA)",
            polyesterGesamt: "Gesamtpolyester (konventionell und nachhaltig)",
            polyesterKonventionell: "Konventionelles Polyester",
            polyesterRecycled: "Recyceltes Polyester (GRS, RCS)",
            tencel: "Tencel",
            linen: "Leinen",
            refibra: "Refibra"
          },
        },
      },
      currentLanguage: 'en',  // Setzen Sie die Standardsprache

      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      pieChartPolyesterTotalConsumptionPercentage,
      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      selectBoxStatusComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,
      currentSelectedYear,
      totalConsumptionData,
      barChartTotalConsumption,

      totalConsumptionDataPercentage,
      pieChartTotalConsumptionPercentage,
    };
  }
  ,
  methods: {

    roundAmount(e) {
      return "Summe: " + this.numberWithCommas(e);
    },

    sumAmount(options) {
      if (options.value !== undefined) {
        if (options.summaryProcess === "start") {
          options.totalValue = 0;
        } else if (options.summaryProcess === "calculate") {

          if (options.value[options.name]) {
            if (options.value[options.name] !== undefined) {
              if (!Number.isNaN(parseFloat(options.value[options.name]))) {
                options.totalValue += parseFloat(options.value[options.name]);
              }
            }
          }

          if (options.name === "totalSum") {
            for (let amount in options.value) {
              if (options.value[amount] !== undefined) {
                if (!Number.isNaN(parseFloat(options.value[amount]))) {
                  options.totalValue += parseFloat(options.value[amount]);
                }
              }
            }
          }

          if (options.totalValue == null) {
            options.totalValue = 0;
          }
        }
      }
      if (options.totalValue === undefined || Number.isNaN(options.totalValue)
      ) {
        options.totalValue = 0;
      }
    },

    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          options.excelCell.font = {name: 'Arial', size: 12};
          options.excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              // eslint-disable-next-line no-undef
              saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'DataGrid.xlsx');
            });
      });
    }
    ,

    calculateTotalAmountPercentagePolyester(kgInText = true) {
      let summedValues = [];

      // Initialisiere die Objekte für die Prozentwerte
      let anteilRecycledPolyesterAnGesamtmengePolyester = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilRecycledPolyesterAnGesamtmengePolyester'],
        value: 0
      };
      let anteilRCSAnGesamtmengePolyester = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilRCSAnGesamtmengePolyester'],
        value: 0
      };
      let anteilGRSAnGesamtmengePolyester = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilGRSAnGesamtmengePolyester'],
        value: 0
      };
      let restlichesPolyesterItem = {
        name: this.texts[this.currentLanguage].chartAndBoxes['restlichePolyester'],
        value: 0
      };

      let polyesterGesamt = 0;
      let anteilRecycledPolyester = 0;
      let anteilRCS = 0;
      let anteilGRS = 0;

      const finishedGoodsConsumptionItems = this.finishedGoodsConsumptionStore.finishedGoodsConsumptionData;

      finishedGoodsConsumptionItems.forEach((item) => {
        // Berechnung der Gesamtmenge Polyester
        polyesterGesamt += (parseFloat(item.net_weight_polyester_conventional) || 0)
            + (parseFloat(item.net_weight_polyester_rcs) || 0)
            + (parseFloat(item.net_weight_polyester_grs) || 0);

        // Berechnung der Anteile
        anteilRecycledPolyester += (parseFloat(item.net_weight_polyester_rcs) || 0)
            + (parseFloat(item.net_weight_polyester_grs) || 0);

        anteilRCS += parseFloat(item.net_weight_polyester_rcs) || 0;

        anteilGRS += parseFloat(item.net_weight_polyester_grs) || 0;
      });

      // Berechnung der Prozentwerte
      anteilRecycledPolyesterAnGesamtmengePolyester.value = parseFloat(this.safeDivide(anteilRecycledPolyester, polyesterGesamt).toFixed(2));
      anteilRCSAnGesamtmengePolyester.value = parseFloat(this.safeDivide(anteilRCS, polyesterGesamt).toFixed(2));
      anteilGRSAnGesamtmengePolyester.value = parseFloat(this.safeDivide(anteilGRS, polyesterGesamt).toFixed(2));

      anteilRecycledPolyesterAnGesamtmengePolyester.valueInKg = parseFloat(anteilRecycledPolyester);
      anteilRCSAnGesamtmengePolyester.valueInKg = parseFloat(anteilRCS);
      anteilGRSAnGesamtmengePolyester.valueInKg = parseFloat(anteilGRS);

      // Berechnung des restlichen Anteils
      let restlichesPolyester = 1 - (
          anteilRecycledPolyesterAnGesamtmengePolyester.value +
          anteilRCSAnGesamtmengePolyester.value +
          anteilGRSAnGesamtmengePolyester.value
      );

      restlichesPolyesterItem.value = parseFloat(restlichesPolyester.toFixed(2));
      restlichesPolyesterItem.valueInKg = parseFloat(polyesterGesamt);

      // Füge die berechneten Werte zur Liste der Summenwerte hinzu
      summedValues.push(
          anteilRecycledPolyesterAnGesamtmengePolyester,
          anteilRCSAnGesamtmengePolyester,
          anteilGRSAnGesamtmengePolyester,
          restlichesPolyesterItem);


      if (kgInText) {
        summedValues.forEach(item => {
          if (item.valueInKg !== undefined && item.name !== undefined) {
            item.name = this.formatWeightToTons(item.valueInKg) + "<br>" + item.name;
          }
        });
      }

      // Setze die berechneten Werte in die Diagrammdaten
      this.pieChartPolyesterTotalConsumptionPercentage = summedValues;

      return summedValues;
    }
    ,

    calculateTotalAmountPercentage(kgInText = true) {
      let summedValues = [];

      let baumwolleGesamtItem = {
        name: this.texts[this.currentLanguage].chartAndBoxes['baumwolleGesamt'],
        value: 0
      };
      let anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle'],
        value: 0
      };
      let anteilFairtradeAnGesamtmengeBaumwolle = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilFairtradeAnGesamtmengeBaumwolle'],
        value: 0
      };
      let anteilGOTSAnGesamtmengeBaumwolle = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilGOTSAnGesamtmengeBaumwolle'],
        value: 0
      };
      let anteilOCSAnGesamtmengeBaumwolle = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilOCSAnGesamtmengeBaumwolle'],
        value: 0
      };
      let anteilCmiaAnGesamtmengeBaumwolle = {
        name: this.texts[this.currentLanguage].chartAndBoxes['anteilCmiaAnGesamtmengeBaumwolle'],
        value: 0
      };

      let restlicheBaumwolleItem = {
        name: this.texts[this.currentLanguage].chartAndBoxes['restlicheBaumwolle'],
        value: 0
      }

      let baumwolleNachhaltigGesamt = 0;
      let anteilFairtrade = 0;
      let anteilGots = 0;
      let anteilOCS = 0;
      let anteilCmia = 0;

      const finishedGoodsConsumptionItems = this.finishedGoodsConsumptionStore.finishedGoodsConsumptionData;

      finishedGoodsConsumptionItems.forEach((item) => {
        baumwolleGesamtItem.value += (parseFloat(item.net_weight_cotton_conventional) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0)
            + (parseFloat(item.net_weight_cotton_gots) || 0)
            + (parseFloat(item.net_weight_cotton_cmia) || 0);

        baumwolleNachhaltigGesamt += (parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0)
            + (parseFloat(item.net_weight_cotton_gots) || 0)
            + (parseFloat(item.net_weight_cotton_cmia) || 0);

        anteilFairtrade += (parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0);

        anteilGots += (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0)
            + (parseFloat(item.net_weight_cotton_gots) || 0);

        anteilOCS += parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0;

        anteilCmia += parseFloat(item.net_weight_cotton_cmia) || 0;
      });

      // Berechnung der Prozentwerte und Rundung auf 2 Nachkommastellen
      anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle.value = parseFloat(this.safeDivide(baumwolleNachhaltigGesamt, baumwolleGesamtItem.value).toFixed(2));
      anteilFairtradeAnGesamtmengeBaumwolle.value = parseFloat(this.safeDivide(anteilFairtrade, baumwolleGesamtItem.value).toFixed(2));
      anteilGOTSAnGesamtmengeBaumwolle.value = parseFloat(this.safeDivide(anteilGots, baumwolleGesamtItem.value).toFixed(2));
      anteilOCSAnGesamtmengeBaumwolle.value = parseFloat(this.safeDivide(anteilOCS, baumwolleGesamtItem.value).toFixed(2));
      anteilCmiaAnGesamtmengeBaumwolle.value = parseFloat(this.safeDivide(anteilCmia, baumwolleGesamtItem.value).toFixed(2));

      anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle.valueInKg = parseFloat(baumwolleNachhaltigGesamt);
      anteilFairtradeAnGesamtmengeBaumwolle.valueInKg = parseFloat(anteilFairtrade);
      anteilGOTSAnGesamtmengeBaumwolle.valueInKg = parseFloat(anteilGots);
      anteilOCSAnGesamtmengeBaumwolle.valueInKg = parseFloat(anteilOCS);
      anteilCmiaAnGesamtmengeBaumwolle.valueInKg = parseFloat(anteilCmia);

      // Berechnung des restlichen Anteils
      let restlicheBaumwolle = 1 - (
          anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle.value +
          anteilFairtradeAnGesamtmengeBaumwolle.value +
          anteilGOTSAnGesamtmengeBaumwolle.value +
          anteilOCSAnGesamtmengeBaumwolle.value +
          anteilCmiaAnGesamtmengeBaumwolle.value
      );

      restlicheBaumwolleItem.value = parseFloat(restlicheBaumwolle.toFixed(2));
      restlicheBaumwolleItem.valueInKg = parseFloat(baumwolleGesamtItem.value);

      // Hinzufügen der Werte zum Diagramm
      summedValues.push(
          anteilNachhaltigeBaumwolleAnGesamtmengeBaumwolle,
          anteilFairtradeAnGesamtmengeBaumwolle,
          anteilGOTSAnGesamtmengeBaumwolle,
          anteilOCSAnGesamtmengeBaumwolle,
          anteilCmiaAnGesamtmengeBaumwolle,
          restlicheBaumwolleItem
      );

      if (kgInText) {
        summedValues.forEach(item => {
          if (item.valueInKg !== undefined && item.name !== undefined) {
            item.name = this.formatWeightToTons(item.valueInKg) + "<br>" + item.name;
          }
        });
      }

      this.pieChartTotalConsumptionPercentage = summedValues;
      return summedValues;
    }
    ,


    safeDivide(numerator, denominator) {
      if (denominator === 0 || isNaN(numerator) || isNaN(denominator)) {
        return 0;
      }
      return parseFloat((numerator / denominator).toFixed(4));
    }
    ,


    calculateTotalAmountInKG(kgInText = true) {

      let summedValues = [];
      let baumwolleGesamt = {name: this.texts[this.currentLanguage].chartAndBoxes['baumwolleGesamt'], value: 0}
      let baumwolleKonventionell = {
        name: this.texts[this.currentLanguage].chartAndBoxes['baumwolleKonventionell'],
        value: 0
      }
      let baumwolleNachhaltig1 = {
        name: this.texts[this.currentLanguage].chartAndBoxes['baumwolleNachhaltig1'],
        value: 0
      }
      let baumwolleNachhaltig2 = {
        name: this.texts[this.currentLanguage].chartAndBoxes['baumwolleNachhaltig2'],
        value: 0
      }
      let baumwolleCmia = {name: this.texts[this.currentLanguage].chartAndBoxes['baumwolleCmia'], value: 0}
      let polyesterGesamt = {name: this.texts[this.currentLanguage].chartAndBoxes['polyesterGesamt'], value: 0}
      let polyesterKonventionell = {
        name: this.texts[this.currentLanguage].chartAndBoxes['polyesterKonventionell'],
        value: 0
      }
      let polyesterRecycled = {name: this.texts[this.currentLanguage].chartAndBoxes['polyesterRecycled'], value: 0}
      let tencel = {name: this.texts[this.currentLanguage].chartAndBoxes['tencel'], value: 0}
      let linen = {name: this.texts[this.currentLanguage].chartAndBoxes['linen'], value: 0}
      let refibra = {name: this.texts[this.currentLanguage].chartAndBoxes['refibra'], value: 0}

      const finishedGoodsConsumptionItems = this.finishedGoodsConsumptionStore.finishedGoodsConsumptionData;

      finishedGoodsConsumptionItems.forEach((item) => {

        baumwolleGesamt.value += (parseFloat(item.net_weight_cotton_conventional) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0)
            + (parseFloat(item.net_weight_cotton_gots) || 0)
            + (parseFloat(item.net_weight_cotton_cmia) || 0);

        baumwolleKonventionell.value += parseFloat(item.net_weight_cotton_conventional) || 0;

        baumwolleNachhaltig1.value += (parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0)
            + (parseFloat(item.net_weight_cotton_gots) || 0)
            + (parseFloat(item.net_weight_cotton_cmia) || 0);

        baumwolleNachhaltig2.value += (parseFloat(item.net_weight_cotton_fairtrade_ocs) || 0)
            + (parseFloat(item.net_weight_cotton_fairtrade_gots) || 0)
            + (parseFloat(item.net_weight_cotton_gots) || 0);

        baumwolleCmia.value += parseFloat(item.net_weight_cotton_cmia) || 0;

        polyesterGesamt.value += (parseFloat(item.net_weight_polyester_conventional) || 0)
            + (parseFloat(item.net_weight_polyester_rcs) || 0)
            + (parseFloat(item.net_weight_polyester_grs) || 0);

        polyesterKonventionell.value += parseFloat(item.net_weight_polyester_conventional) || 0;

        polyesterRecycled.value += (parseFloat(item.net_weight_polyester_rcs) || 0)
            + (parseFloat(item.net_weight_polyester_grs) || 0);

        tencel.value += parseFloat(item.net_weight_tencel) || 0;
        linen.value += parseFloat(item.net_weight_linen) || 0;
        refibra.value += parseFloat(item.net_weight_refibra) || 0;


      })

      summedValues.push(baumwolleGesamt, baumwolleKonventionell, baumwolleNachhaltig1, baumwolleNachhaltig2, baumwolleCmia,
          polyesterGesamt, polyesterKonventionell, polyesterRecycled, tencel, linen);

      if (this.currentSelectedYear <= 2023) {
        summedValues.push(refibra);
      }

      if (kgInText) {
        summedValues.forEach(this.formatVariable.bind(this));
      }

      return summedValues;
    }
    ,


    formatVariable(variable) {
      variable.name = this.formatWeightToKg(variable.value) + "<br>" + variable.name;
    },

    formatWeightToTons(kg) {
      // Überprüfe, ob kg eine gültige Zahl ist
      if (typeof kg !== 'number' || isNaN(kg)) {
        kg = 0;
      }

      // Umrechnung von Kilogramm in Tonnen
      let tons = kg / 1000;

      // Runden auf zwei Dezimalstellen und formatieren
      let formattedTons = this.numberWithCommas(tons.toFixed(2)) + ' t';
      return `${formattedTons}`;
    },

    formatWeightToKg(kg) {
      // Überprüfe, ob kg eine gültige Zahl ist
      if (typeof kg !== 'number' || isNaN(kg)) {
        kg = 0;
      }

      // Runden auf zwei Dezimalstellen und formatieren
      let formattedKg = this.numberWithCommas(kg.toFixed(2)) + ' kg';
      return `${formattedKg}`;
    }
    ,

    formatWeight(kg) {
      // Überprüfe, ob kg eine gültige Zahl ist
      if (typeof kg !== 'number' || isNaN(kg)) {
        kg = 0;
      }

      // Runden auf zwei Dezimalstellen und formatieren
      let formattedKg = this.numberWithCommas(kg.toFixed(2)) + ' kg';
      let formattedTons = this.numberWithCommas((kg / 1000).toFixed(2)) + ' t';
      return `${formattedKg} | ${formattedTons}`;
    }
    ,
    numberWithCommas(x) {

      // Wenn der Eingabewert ein String ist, konvertiere ihn in eine Zahl
      if (typeof x === 'string') {
        x = parseFloat(x);
      }

      // Runde die Zahl auf zwei Dezimalstellen
      let roundedNumber = x.toFixed(2);

      // Teile die Zahl in ganzen Teil und Dezimalteil
      let parts = roundedNumber.split('.');

      // Füge Punkte für den ganzen Teil hinzu
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // Füge die Teile wieder zusammen
      return parts.join(',');
    },


    /**
     * Cell Prepared Styles
     */

    /**
     * Value Change Events
     */

    languageCheckBoxChanged(item) {
      this.currentLanguage = item.value;

    }
    ,

    async selectBoxTourValueChangedEvent(item) {
      this.currentSelectedYear = item.value;
      await this.finishedGoodsConsumptionStore.getFinishedGoodsConsumptionsByYear(item.value);
      await this.orderDataGridInstance.refresh();
    }
    ,

    /**
     * Filter Grid Functions
     * */

  }
  ,
  computed: {

    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent?.instance;
    }
    ,
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent?.instance;
    }
    ,
  }
  ,
}
;
</script>

<style scoped>
.eingabe {
  background-color: #dcecca !important;
}

.summary-card {
  background-color: white;
}

.totalGrid n-card {
  margin-top: 20px;
}

.break-word {
  word-wrap: break-word;
  white-space: normal !important;
}

#chart {
  height: 440px;
}
</style>
